<template>
  <div class="flex flex-col justify-center items-center p-5">
    <div>
      <img class="md:h-auto h-44" :src="success" alt="success" />
    </div>
    <div v-if="userStore.user" class="my-4 text-center font-medium text-xl">
      <p>
        Votre réservation pour
        <span class="text-secondary"> {{ userStore.user[0].eventName }}</span>
        au date
        <span class="text-secondary">{{ userStore.user[0].eventDate }}</span> à
        été enregsitrée avec succès.
      </p>
    </div>
    <div class="hidden md:grid grid-cols-3 gap-4">
      <div v-for="(item, index) in qrDataCodes" :key="index">
        <vue-qrcode
          :key="index"
          class="w-64 h-64"
          id="qrCode"
          :value="item"
          @change="onDataUrlChange"
        />
        <button
          @click="downloadImage(index)"
          type="submit"
          class="bg-secondary px-6 py-2 rounded-md text-white my-4 shadow-secondary shadow-sm"
        >
          Téléchargez votre Code QR {{ index + 1 }}
        </button>
      </div>
    </div>

    <div class="md:hidden">
      <div v-for="(item, index) in qrDataCodes" :key="index">
        <vue-qrcode
          :key="index"
          class="w-64 h-64"
          id="qrCode"
          :value="item"
          @change="onDataUrlChange"
        />
        <button
          @click="downloadImage(index)"
          type="submit"
          class="bg-secondary px-6 py-2 rounded-md text-white my-4 shadow-secondary shadow-sm"
        >
          Téléchargez votre Code QR {{ index + 1 }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import success from "@/assets/icons/success.svg";
import { useUserStore } from "@/stores/usersStore";

import VueQrcode from "vue-qrcode";

export default {
  data() {
    return { qrDataCodes: [] };
  },
  mounted() {
    for (let index = 0; index < this.userStore.user.length; index++) {
      const qrData = {
        id: this.userStore.user[index].id,
        eventName: this.userStore.user[index].eventName,
        full_name: this.userStore.user[index].full_name,
        phone: this.userStore.user[index].phone,
        email: this.userStore.user[index].email,
      };
      this.qrDataCodes.push(JSON.stringify(qrData));
    }
  },

  setup() {
    const userStore = useUserStore();

    return {
      success,
      userStore,
    };
  },
  components: {
    VueQrcode,
  },
  methods: {
    onDataUrlChange() {
      let data = {
        id: this.userStore.user.id,
        phone: this.userStore.user.phone,
        email: this.userStore.user.email,
      };
      this.qrData = JSON.stringify(data);
    },
    downloadImage(index) {
      const link = document.createElement("a");
      const qrcodeImages = document.querySelectorAll("#qrCode");
      link.href = qrcodeImages[index].src;
      link.download = "reservation" + index + ".png";
      link.click();
    },
  },
};
</script>
<style></style>
