<template>
  <div>
    <div class="flex justify-between items-center mt-10">
      <div class="md:ml-4 text-2xl font-semibold">Evénements</div>
      <div class="flex items-center">
        <div class="mr-2 font-medium">
          {{ moderatorsStore?.moderator?.moderator?.full_name }}
        </div>
        <div><img class="rounded-full" :src="avatar" alt="avatar" /></div>
      </div>
    </div>

    <div class="w-full mb-12 xl:mb-0 px-4">
      <events-table />
    </div>
  </div>
</template>
<script>
import EventsTable from "@/components/tables/EventsTable.vue";
import avatar from "@/assets/images/avatar.png";
import addReservation from "@/assets/icons/addReservation.svg";
import peopleCount from "@/assets/icons/peopleCount.svg";
import scanQr from "@/assets/icons/scanQr.svg";
import { useModeratorsStore } from "@/stores/moderatorsStore";

export default {
  name: "dashboard-page",
  data() {
    return {
      showAddModal: false,
    };
  },
  setup() {
    const moderatorsStore = useModeratorsStore();

    return {
      avatar,
      addReservation,
      peopleCount,
      scanQr,
      moderatorsStore,
    };
  },
  components: {
    EventsTable,
  },
};
</script>
