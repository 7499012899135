<template>
  <div>
    <add-reservation v-if="showAddModal" @close="showAddModal = false" />
    <success-scan-modal v-if="reservationStore.isSuccessModal" />
    <fail-scan-modal v-if="reservationStore.isFailModal" />
    <div class="flex justify-between items-center mt-10">
      <div class="md:ml-4 text-2xl font-semibold">Dashboard</div>
      <div class="flex items-center">
        <div class="mr-2 font-medium">
          {{ moderatorsStore?.moderator?.moderator?.full_name }}
        </div>
        <div><img class="rounded-full" :src="avatar" alt="avatar" /></div>
      </div>
    </div>
    <div
      :class="isSuperAdmin ? ' justify-between' : ' justify-evenly'"
      class="md:flex justify-between my-4"
    >
      <div
        :class="isSuperAdmin ? ' md:w-[30%]' : ' md:w-[45%]'"
        class="bg-secondary text-center p-8 md:w-[30%] my-3 md:my-0 rounded-xl cursor-pointer"
        @click="showAddModal = true"
      >
        <img class="mx-auto mb-3" :src="addReservation" alt="icon" />
        <p class="text-white font-semibold">Ajouter une réservation</p>
      </div>
      <div
        :class="isSuperAdmin ? ' md:w-[30%]' : ' md:w-[45%]'"
        class="text-center p-8 md:w-[30%] my-3 md:my-0 rounded-xl border border-gray-300"
      >
        <img class="mx-auto mb-3" :src="peopleCount" alt="icon" />
        <p class="text-secondary">{{ reservationStore.reservationsCounts }}</p>
      </div>
      <div
        v-if="isSuperAdmin"
        @click="showQrDecoder = true"
        class="bg-secondary text-center p-8 md:w-[30%] my-3 md:my-0 rounded-xl"
      >
        <img class="mx-auto mb-3" :src="scanQr" alt="icon" />
        <p class="text-white font-semibold">Scan Code QR</p>
      </div>
    </div>
    <div v-if="showQrDecoder" class="md:w-6/12 md:mx-auto md:h-[400px]">
      <qr-stream @decode="onDecode" @init="onInit" />
    </div>

    <div class="w-full mb-12 xl:mb-0 px-4">
      <dashboard-table />
    </div>
  </div>
</template>
<script>
import DashboardTable from "@/components/tables/DashboardTable.vue";
import avatar from "@/assets/images/avatar.png";
import addReservation from "@/assets/icons/addReservation.svg";
import peopleCount from "@/assets/icons/peopleCount.svg";
import scanQr from "@/assets/icons/scanQr.svg";
import AddReservation from "@/components/modals/AddReservation.vue";
import { QrStream } from "vue3-qr-reader";
import { useModeratorsStore } from "@/stores/moderatorsStore";
import { useReservationStore } from "@/stores/reservationsStore";
import SuccessScanModal from "@/components/modals/SuccessScanModal.vue";
import FailScanModal from "@/components/modals/FailScanModal.vue";

export default {
  name: "dashboard-page",
  data() {
    return {
      showAddModal: false,
      showQrDecoder: false,
      result: null,
      error: "",
      qrcodeResult: "",
    };
  },
  watch: {
    qrcodeResult(reservationTxt) {
      this.reservationStore.isSuccessModal = false;
      this.reservationStore.isFailModal = false;
      this.reservationStore.isAlreadyPresent = false;
      this.reservationStore.isNotExisting = false;
      try {
        const reservation = JSON.parse(reservationTxt);
        this.reservationStore.checkReservation(reservation);
      } catch (error) {
        this.reservationStore.isFailModal = true;
      }
    },
  },
  methods: {
    onDecode(result) {
      this.qrcodeResult = result;
      this.showQrDecoder = false;
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
  },
  setup() {
    const moderatorsStore = useModeratorsStore();
    const reservationStore = useReservationStore();
    const isSuperAdmin =
      moderatorsStore?.$state?.moderator?.moderator?.role == "super-admin"
        ? true
        : false;
    return {
      avatar,
      addReservation,
      peopleCount,
      scanQr,
      moderatorsStore,
      reservationStore,
      isSuperAdmin,
    };
  },
  components: {
    DashboardTable,
    AddReservation,
    QrStream,
    SuccessScanModal,
    FailScanModal,
  },
};
</script>
