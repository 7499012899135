import router from "@/router";
import axios from "axios";
import { defineStore } from "pinia";

export const useUserStore = defineStore("userStore", {
  state: () => ({
    user: null,
    error: null,
  }),
  actions: {
    async authUser(credentials) {
      this.error = null;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE}users/auth`,
          credentials
        );
        if (response?.data?.reservations) {
          this.user = response.data.reservations;
          router.push("/reservation");
        }
      } catch (err) {
        this.error = err.response.data;
      }
    },
  },
});
