<template>
  <div>
    <form @submit.prevent="updateEvent" v-if="event">
      <div class="my-1">
        <p class="text-[#7C7C7C] mb-1 text-left">Titre</p>
        <input
          type="text"
          class="text-gray-700 px-2 leading-tight focus:outline-none border rounded-md w-full py-3"
          v-model="updatedEvent.title"
        />
        <p v-if="v$.updatedEvent.title.$error" class="text-red-500 my-2">
          Nom est requis requis
        </p>
      </div>
      <div class="md:flex md:justify-between">
        <div class="my-1 w-full">
          <p class="text-[#7C7C7C] mb-1 text-left">Date</p>
          <input
            type="date"
            class="text-gray-700 px-2 leading-tight focus:outline-none border rounded-md w-full py-3"
            v-model="updatedEvent.date"
          />
          <p v-if="v$.updatedEvent.date.$error" class="text-red-500 my-2">
            date est requis
          </p>
        </div>
      </div>

      <div class="flex justify-center my-4">
        <button
          class="bg-secondary px-6 py-2 text-white font-semibold rounded-md"
          type="submit"
        >
          Enregistrer
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import { useEventsStore } from "@/stores/eventsStore";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  props: ["event"],
  data() {
    return {
      updatedEvent: {
        title: "",
        date: "",
        id: 0,
      },
    };
  },
  mounted() {
    this.updatedEvent.title = this.event.title;
    const formattedDate = this.event.date.split("T")[0];
    this.updatedEvent.date = formattedDate;
    this.updatedEvent.id = this.event.id;
  },
  setup() {
    const eventStore = useEventsStore();
    return { v$: useVuelidate(), eventStore };
  },
  methods: {
    async updateEvent() {
      const result = await this.v$.$validate();
      if (!result) {
        return this.v$.$errors;
      } else {
        this.eventStore.updateEvent(this.updatedEvent);
        alert("Modifié avec succées");
        window.location.reload();
      }
    },
    handleCoverChange(event) {
      this.cover = event.target.files[0];
    },
  },
  validations() {
    return {
      updatedEvent: {
        title: { required },
        date: { required },
      },
    };
  },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
