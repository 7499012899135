<template>
  <div
    class="flex login-page 3xl:justify-between justify-center items-center pt-20 md:pt-0 max-w-[1728px] lg:mx-auto xl:min-h-screen"
  >
    <div
      class="lg:flex items-center md:border border-gray-200 rounded-md md:p-5 md:pt-10"
    >
      <div class="px-4">
        <img :src="logo" alt="logo" class="mx-auto lg:w-[110px]" />
        <p class="text-center opacity-40 pt-4">Plateforme des réservation</p>

        <h1
          v-if="$route.path == '/auth/login'"
          class="my-3 lg:my-6 text-[22px] lg:text-[24px] font-medium text-black text-center"
        >
          Connectez-vous
        </h1>
        <h1
          v-if="$route.path == '/login'"
          class="my-3 lg:my-6 text-[22px] lg:text-[24px] font-medium text-black text-center"
        >
          Consultez vos demandes de réservations
        </h1>
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import logo from "@/assets/icons/logo.svg";
export default {
  setup() {
    return { logo };
  },
};
</script>
<style scoped>
input::-webkit-input-placeholder {
  color: rgba(41, 44, 48, 0.8);
  font-weight: 400;
}
.socials-logo {
  height: 20px;
}
</style>
