<template>
  <div class="modal-mask">
    <div class="modal-wrapper rounded-xl">
      <div class="modal-container rounded-xl p-10">
        <div class="float-right -mt-5 -mr-5">
          <button @click="$emit('close')">
            <img :src="exitIcon" alt="exit" />
          </button>
        </div>
        <div class="text-center font-medium text-black my-8">
          <slot name="body">
            Vous voulez vraiment supprimer cet point de vente?
          </slot>
        </div>
        <div class="flex justify-evenly">
          <button
            class="bg-white px-6 py-2 rounded-md text-secondary my-4 border border-secondary"
            @click="$emit('close')"
          >
            Annuler
          </button>
          <button
            class="bg-secondary px-6 py-2 rounded-md text-white my-4 shadow-secondary shadow-sm"
            @click="deleteModerator"
          >
            Supprimer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import exitIcon from "@/assets/icons/exit.svg";
import { useModeratorsStore } from "@/stores/moderatorsStore";
export default {
  props: ["moderator"],

  methods: {
    async deleteModerator() {
      await this.moderatorsStore.deleteModerator(this.moderator.id);
      this.$emit("close");
    },
  },
  setup() {
    const moderatorsStore = useModeratorsStore();
    return {
      exitIcon,
      moderatorsStore,
    };
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.8s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
