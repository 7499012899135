import { defineStore } from "pinia";
import axios from "axios";
export const useEventsStore = defineStore("eventsStore", {
  state: () => ({
    events: [],
    error: null,
    event: null,
    itemsPerPage: 10,
    currentPage: 1,
  }),
  getters: {
    paginatedList(state) {
      const startIndex = (state.currentPage - 1) * state.itemsPerPage;
      const endIndex = startIndex + state.itemsPerPage;
      return state.events.slice(startIndex, endIndex);
    },
    totalPages(state) {
      return Math.ceil(state.events.length / state.itemsPerPage);
    },
  },

  actions: {
    async addEvent(event) {
      try {
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        const moderatorId = JSON.parse(moderator).moderator.id;
        const newEvent = {
          ...event,
          moderatorId: moderatorId,
        };
        console.log(newEvent);
        await axios.post(`${process.env.VUE_APP_API_BASE}event/`, newEvent, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.getEvents();
      } catch (err) {
        console.log(err);
      }
    },
    async getEvents() {
      try {
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}event/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.events = data.events;
      } catch (err) {
        console.log(err);
      }
    },
    async deleteEvent(eventId) {
      try {
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        await axios.delete(`${process.env.VUE_APP_API_BASE}event/${eventId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.getEvents();
      } catch (err) {
        console.log(err);
      }
    },
    async updateEvent(updatedEvent) {
      try {
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        await axios.put(
          `${process.env.VUE_APP_API_BASE}event/${updatedEvent.id}`,
          updatedEvent,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.getEvents();
      } catch (err) {
        console.log(err);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
});
