<template>
  <div class="max-w-[1728px] lg:mx-auto">
    <side-bar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <div class="px-4 md:px-5 mx-auto w-full">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import SideBar from "@/components/sidebars/SideBar.vue";
export default {
  name: "admin-layout",
  components: {
    SideBar,
  },
};
</script>
