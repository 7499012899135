<template>
  <div class="events-actions">
    <UpdateEvent
      v-if="showUpdateModal"
      :event="event"
      @close="showUpdateModal = false"
    />
    <DeleteEventModal
      v-if="showDeleteModal"
      :event="event"
      @close="showDeleteModal = false"
    />
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex justify-center">
        <img :src="actions" alt="actions" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        href="javascript:void(0);"
        @click="showUpdateModal = true"
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
      >
        <img :src="edit" class="mr-2" alt="consult" />

        Modifier </a
      ><a
        href="javascript:void(0);"
        @click="showDeleteModal = true"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="deleteIcon" class="mr-2" alt="block" />

        Suprimer
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import actions from "@/assets/icons/actions.svg";
import deleteIcon from "@/assets/icons/delete.svg";
import edit from "@/assets/icons/edit.svg";
import UpdateEvent from "../modals/UpdateEvent.vue";
import DeleteEventModal from "../modals/DeleteEventModal.vue";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      showUpdateModal: false,
      showDeleteModal: false,
    };
  },
  props: ["event"],
  setup() {
    return {
      actions,
      deleteIcon,
      edit,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".events-actions");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
  components: { UpdateEvent, DeleteEventModal },
};
</script>
