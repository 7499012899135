import { createRouter, createWebHistory } from "vue-router";
// layouts
import AdminLayout from "../layouts/AdminLayout.vue";
import LoginLayout from "../layouts/LoginLayout.vue";
// views

///1-login
import LoginView from "../views/login/LoginView.vue";
import UsersLogin from "../views/login/UsersLogin.vue";
///2-dashboard
import DashboardView from "../views/dashboard/DashboardView.vue";
import SettingsView from "../views/dashboard/SettingsView.vue";
import ReservationView from "../views/dashboard/ReservationView.vue";
import EventsView from "../views/dashboard/EventsView.vue";

//3- user view
import SingleReservationView from "../views/reservation/SingleReservationView.vue";
import { useModeratorsStore } from "@/stores/moderatorsStore";
import { useUserStore } from "@/stores/usersStore";
const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/auth",
  },
  {
    path: "/auth",
    name: "auth",
    redirect: "/auth/login",
    component: LoginLayout,
    children: [
      {
        path: "/auth/login",
        name: "login",
        component: LoginView,
      },
    ],
  },
  {
    path: "/login",
    name: "usersLogin",
    redirect: "/login",
    component: LoginLayout,
    children: [
      {
        path: "/login",
        name: "usersLogin",
        component: UsersLogin,
      },
    ],
  },
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: AdminLayout,
    children: [
      {
        path: "/admin/dashboard",
        component: DashboardView,
      },
      {
        path: "/admin/settings",
        component: SettingsView,
      },
      {
        path: "/admin/reservations",
        component: ReservationView,
      },
      {
        path: "/admin/events",
        component: EventsView,
      },
    ],
  },
  {
    path: "/reservation",
    name: "user-login",
    component: SingleReservationView,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("penista-moderator");
  token = JSON.parse(token);
  const moderatorsStore = useModeratorsStore();
  const usersStore = useUserStore();
  moderatorsStore.moderator = token;

  if (to.path.includes("admin") && !moderatorsStore.moderator) {
    return next({ path: "/auth/login" });
  }

  if (to.name == "user-login" && !usersStore.user) {
    return next({ path: "/login" });
  }
  
  if (moderatorsStore.moderator != null) {
    const isSuperAdmin =
      moderatorsStore?.moderator?.moderator?.role == "super-admin"
        ? true
        : false;
    if (to.path == "/admin/settings" && !isSuperAdmin) {
      return next({ path: "/admin/dashboard" });
    }
    if (to.path == "/admin/events" && !isSuperAdmin) {
      return next({ path: "/admin/dashboard" });
    }
  }

  if (to.name == "login" && token) {
    return next({ path: "/admin/dashboard" });
  }
  if (to.meta.requiresAuth && !token) {
    return next({ name: "auth" });
  } else {
    return next();
  }
});

export default router;
