<template>
  <nav
    class="md:left-0 bg-secondary shadow-2xl md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-4 rounded-se-3xl rounded-ee-3xl"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        v-on:click="toggleCollapseShow(' m-2 py-3 px-6')"
      >
        <img :src="menuBurger" alt="menuBurger" />
      </button>
      <!-- Brand -->
      <router-link
        class="hidden md:flex justify-center items-center text-left md:pb-2 text-blueGray-600 mr-0 whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
        to="/"
      >
        <img :src="logo" class="inline" alt="logo" />
      </router-link>
      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative"></li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded bg-secondary"
        v-bind:class="collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12"></div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="toggleCollapseShow('hidden')"
              >
                <img :src="closeMenu" alt="closeMenu" />
              </button>
            </div>
          </div>
        </div>

        <!-- Navigation -->

        <ul
          class="md:flex-col md:min-w-full mt-10 flex flex-col list-none text-[#999999]"
        >
          <li class="items-center">
            <router-link
              to="/admin/dashboard"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex text-white"
                :class="[
                  isActive || $route.path.includes('dashboard')
                    ? 'text-white selected-route font-semibold rounded-md'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <img class="mr-2" :src="dashboard" alt="dashboard" />
                Dashboard
              </a>
            </router-link>
          </li>
          <li class="items-center">
            <router-link
              to="/admin/reservations"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex text-white"
                :class="[
                  isActive || $route.path.includes('news')
                    ? 'text-white font-semibold rounded-md selected-route'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <img class="mr-2 ml-1" :src="addReservation2" alt="dashboard" />

                Réservations
              </a>
            </router-link>
          </li>
          <li v-if="isSuperAdmin" class="items-center">
            <router-link
              to="/admin/events"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex text-white"
                :class="[
                  isActive || $route.path.includes('news')
                    ? 'text-white font-semibold rounded-md selected-route'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <img class="mr-2 ml-1" :src="event" alt="dashboard" />

                Evénements
              </a>
            </router-link>
          </li>
          <li v-if="isSuperAdmin" class="items-center">
            <router-link
              to="/admin/settings"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex text-white"
                :class="[
                  isActive || $route.path.includes('detection')
                    ? 'text-white selected-route font-semibold rounded-md'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <img class="mr-2" :src="sellPoint" alt="dashboard" />

                Point de vente
              </a>
            </router-link>
          </li>
        </ul>
      </div>
      <div>
        <ul>
          <li class="items-center" @click="moderatorsStore.disconnect()">
            <router-link to="/admin/dashboard">
              <a class="uppercase py-3 font-bold flex text-white">
                <img class="mr-2" :src="logout" alt="logout" />
                Se déconnecter
              </a>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import logo from "@/assets/icons/logo-white.svg";
import menuBurger from "@/assets/icons/menuBurger.svg";
import closeMenu from "@/assets/icons/closeMenu.svg";
import event from "@/assets/icons/event.svg";
import dashboard from "@/assets/icons/dashboard.svg";
import addReservation2 from "@/assets/icons/addReservation2.svg";
import sellPoint from "@/assets/icons/sellPoint.svg";
import logout from "@/assets/icons/logout.svg";
import { useModeratorsStore } from "@/stores/moderatorsStore";

export default {
  components: {},
  data() {
    return {
      collapseShow: "hidden",
    };
  },
  setup() {
    const moderatorsStore = useModeratorsStore();
    const isSuperAdmin =
      moderatorsStore?.$state?.moderator?.moderator?.role == "super-admin"
        ? true
        : false;
    return {
      logo,
      menuBurger,
      event,
      closeMenu,
      sellPoint,
      addReservation2,
      dashboard,
      logout,
      moderatorsStore,
      isSuperAdmin,
    };
  },
  methods: {
    toggleCollapseShow: function (classes) {
      this.collapseShow = classes;
    },
  },
};
</script>
<style scoped>
.selected-route {
  background: rgba(255, 255, 255, 0.25);
}
.svg-active {
  fill: white !important;
}
.svg-inactive {
  fill: rgba(0, 0, 0, 0.4) !important;
}
.box-example2 {
  box-shadow: inset 0px 7px 9px -7px rgb(0 0 0 / 40%);
  height: 80px;
  border: 1px dashed grey;
  position: relative;
  margin-top: 8px;
}
nav::after {
  box-shadow: inset -7px 0px 090px -7px rgb(0 0 0 / 40%) !important;
}
</style>
