import { defineStore } from "pinia";
import axios from "axios";
export const useReservationStore = defineStore("reservationStore", {
  state: () => ({
    reservations: [],
    lastestReservations: [],
    reservationsCount: 0,
    itemsPerPage: 10,
    currentPage: 1,
    isAlreadyPresent: false,
    isSuccessModal: false,
    isFailModal: false,
    isNotExisting: false,
    reservationStatus: null,
  }),
  getters: {
    paginatedList(state) {
      const startIndex = (state.currentPage - 1) * state.itemsPerPage;
      const endIndex = startIndex + state.itemsPerPage;
      return state.reservations.slice(startIndex, endIndex);
    },
    reservationsCounts(state) {
      return state.reservations.length;
    },
    totalPages(state) {
      return Math.ceil(state.reservations.length / state.itemsPerPage);
    },
  },
  actions: {
    async addReservation(reservation) {
      console.log(reservation);
      try {
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        const moderatorId = JSON.parse(moderator).moderator.id;
        const newReservation = {
          ...reservation,
          moderatorId: moderatorId,
        };
        await axios.post(
          `${process.env.VUE_APP_API_BASE}reservation/`,
          newReservation,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.getReservations();
      } catch (err) {
        console.log(err);
      }
    },
    async getReservations() {
      try {
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}reservation/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.reservations = data.reservations;
      } catch (err) {
        console.log(err);
      }
    },
    async getReservationsModerator() {
      try {
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        const id = JSON.parse(moderator).moderator.id;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}reservation/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.reservations = data.reservations;
      } catch (err) {
        console.log(err);
      }
    },
    async getLastestReservations() {
      try {
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}reservation/lastest`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.lastestReservations = data.reservations;
      } catch (err) {
        console.log(err);
      }
    },
    async getLastestReservationsModerator() {
      try {
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        const id = JSON.parse(moderator).moderator.id;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}reservation/lastest/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.lastestReservations = data.reservations;
      } catch (err) {
        console.log(err);
      }
    },
    async checkReservation(reservation) {
      try {
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        const { data } = await axios.patch(
          `${process.env.VUE_APP_API_BASE}reservation/`,
          reservation,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.isSuccessModal = true;
        this.reservationStatus = data.message;
        this.getReservations();
      } catch (err) {
        switch (err.response.status) {
          case 200:
            this.isSuccessModal = true;
            break;
          case 400:
            this.isFailModal = true;
            this.isAlreadyPresent = true;
            break;
          case 404:
            this.isFailModal = true;
            this.isNotExisting = true;
            break;
          default:
            break;
        }
        console.log(err);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
});
