import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router";
export const useModeratorsStore = defineStore("moderatorsStore", {
  state: () => ({
    moderators: [],
    error: null,
    moderator: null,
    itemsPerPage: 10,
    currentPage: 1,
  }),
  getters: {
    paginatedList(state) {
      const startIndex = (state.currentPage - 1) * state.itemsPerPage;
      const endIndex = startIndex + state.itemsPerPage;
      return state.moderators.slice(startIndex, endIndex);
    },
    totalPages(state) {
      return Math.ceil(state.moderators.length / state.itemsPerPage);
    },
  },
  actions: {
    async authenticate(credentials) {
      console.log("auth auith");
      this.error = null;

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE}moderator/auth`,
          credentials
        );
        console.log(response);
        if (response?.data?.token && response?.data?.moderator) {
          this.moderator = response.data;
          localStorage.setItem(
            "penista-moderator",
            JSON.stringify(this.moderator)
          );
          router.push("/admin");
        }
      } catch (err) {
        console.log(err);
        this.error = "Invalide email ou mot de pass";
      }
    },
    disconnect() {
      this.error = null;
      this.moderator = null;
      localStorage.removeItem("penista-moderator");
      router.push("/auth");
    },
    async addModerator(newModerator) {
      try {
        this.error = null;
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}moderator/`,
          newModerator,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.getModerators();
      } catch (err) {
        console.log(err);
        this.error = err.response.data.message;
      }
    },
    async getModerators() {
      try {
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}moderator/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.moderators = data.moderators.filter((moderator) => {
          return moderator.role !== "super-admin";
        });
      } catch (err) {
        console.log(err);
      }
    },
    async deleteModerator(moderatorId) {
      try {
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        await axios.delete(
          `${process.env.VUE_APP_API_BASE}moderator/${moderatorId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.getModerators();
      } catch (err) {
        console.log(err);
      }
    },
    async updateModerator(updatedModerator) {
      try {
        console.log("access store function");
        const moderator = localStorage.getItem("penista-moderator");
        const token = JSON.parse(moderator).token;
        await axios.put(
          `${process.env.VUE_APP_API_BASE}moderator/${updatedModerator.id}`,
          updatedModerator,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.getModerators();
      } catch (err) {
        console.log(err);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
});
