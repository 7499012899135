<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
  >
    <moderator-modal
      v-if="showModeratorModal"
      @close="showModeratorModal = false"
    />
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="text-lg text-primary font-medium">
            Liste des points de vente
          </h3>
        </div>
        <div>
          <button
            @click="showModeratorModal = true"
            class="flex bg-secondary px-6 py-2 rounded-md text-white my-4 shadow-secondary shadow-sm"
          >
            <img :src="addUser" class="mr-2" alt="addUser" />
            Nouveau point de vente
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Clients table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead class="table-header">
          <tr class="border-b">
            <th
              class="table-th px-6 flex items-center align-middle border border-solid py-3 uppercase border-l-0 border-b-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              ID Pdev
            </th>
            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Nom complet
            </th>
            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Téléphone
            </th>
            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Email
            </th>
            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Nombre réseravations ajouté
            </th>
            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Date d’ajout
            </th>
            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(moderator, index) in moderatorsStore.paginatedList"
            :key="index"
            class="border-b"
          >
            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              #{{ moderator.id }}
            </td>

            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ moderator.full_name }}
            </td>

            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ moderator.phone }}
            </td>

            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ moderator.email }}
            </td>

            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-center"
            >
              {{ moderator.addedReservations }}
            </td>

            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ moderator.createdAt.substring(0, 10) }}
            </td>

            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-right"
            >
              <dashboard-actions-dropdown :moderator="moderator" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-around w-full py-6">
      <div>
        <button
          @click="moderatorsStore.previousPage"
          :disabled="moderatorsStore.currentPage === 1"
          class="border text-darkBlue px-3 py-2 rounded-md"
        >
          Précédente
        </button>
      </div>
      <div class="text-darkBlue flex justify-center items-center">
        Page {{ moderatorsStore.currentPage }} sur
        {{ moderatorsStore.totalPages }}
      </div>
      <div>
        <button
          @click="moderatorsStore.nextPage"
          :disabled="moderatorsStore.currentPage === moderatorsStore.totalPages"
          class="border text-darkBlue px-3 py-2 rounded-md"
        >
          Prochaine
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardActionsDropdown from "@/components/dropdowns/DashboardActionsDropdown.vue";
import addUser from "@/assets/icons/addUser.svg";
import ModeratorModal from "../modals/ModeratorModal.vue";
import { useModeratorsStore } from "@/stores/moderatorsStore";
export default {
  data() {
    return { showModeratorModal: false };
  },
  async mounted() {
    await this.moderatorsStore.getModerators();
  },
  setup() {
    const moderatorsStore = useModeratorsStore();
    return { addUser, moderatorsStore };
  },
  components: {
    DashboardActionsDropdown,
    ModeratorModal,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
<style scoped>
.table-header {
  background: #f9fafb;
}
.table-th {
  color: #8a92a6;
}
</style>
