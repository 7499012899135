<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="text-lg text-primary font-medium">Réservations</h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Clients table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead class="table-header">
          <tr class="border-b">
            <th
              class="table-th px-6 flex items-center align-middle border border-solid py-3 uppercase border-l-0 border-b-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              ID Utilisateur
            </th>
            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Nom complet
            </th>
            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Téléphone
            </th>
            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Email
            </th>
            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-center"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(reservation, index) in reservationStore.lastestReservations"
            :key="index"
            class="border-b"
          >
            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              #{{ reservation.id }}
            </td>

            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ reservation.full_name }}
            </td>

            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ reservation.phone }}
            </td>

            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ reservation.email }}
            </td>
            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              <reserved-ticket v-if="reservation.status == 'reserved'" />
              <present-ticket v-if="reservation.status == 'present'" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import reservedTicket from "@/components/chips/reservedTicket.vue";
import { useReservationStore } from "@/stores/reservationsStore";
import PresentTicket from "../chips/presentTicket.vue";

export default {
  data() {
    return {};
  },
  async mounted() {
    const moderator = localStorage.getItem("penista-moderator");
    const role = JSON.parse(moderator).moderator.role;
    switch (role) {
      case "super-admin":
        await this.reservationStore.getLastestReservations();
        break;
      case "moderator":
        await this.reservationStore.getLastestReservationsModerator();
        break;
      default:
        break;
    }
  },
  setup() {
    const reservationStore = useReservationStore();
    return { reservationStore };
  },
  components: {
    reservedTicket,
    PresentTicket,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
<style scoped>
.table-header {
  background: #f9fafb;
}
.table-th {
  color: #8a92a6;
}
</style>
