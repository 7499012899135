<template>
  <div
    class="rounded-xl text-center px-2 py-[2px] font-medium text-[#AFAFAF] border-[#AFAFAF] border"
  >
    réservé
  </div>
</template>
<script>
export default {};
</script>
<style></style>
