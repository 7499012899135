<template>
  <div class="modal-mask">
    <div class="modal-wrapper rounded-xl">
      <div class="modal-container md:w-[800px] rounded-xl p-10">
        <div class="float-right -mt-5 -mr-5">
          <button @click="closeModal">
            <img :src="exitIcon" alt="exit" />
          </button>
        </div>
        <div class="text-center font-medium text-black my-8 text-xl"></div>
        <div>
          <img :src="failedScan" alt="success" class="mx-auto" />
        </div>
        <div
          v-if="reservationStore.isAlreadyPresent"
          class="text-center my-4 font-medium text-x text-red-600"
        >
          Cet utilisateur est déjà marqué comme présent !
        </div>
        <div
          v-if="reservationStore.isNotExisting"
          class="text-center my-4 font-medium text-x text-red-600"
        >
          Cet utilisateur n’existe pas !
        </div>
        <div
          v-if="
            !reservationStore.isNotExisting &&
            !reservationStore.isAlreadyPresent
          "
          class="text-center my-4 font-medium text-x text-red-600"
        >
          Cet utilisateur n’existe pas !
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import exitIcon from "@/assets/icons/exit.svg";
import failedScan from "@/assets/images/failedScan.png";
import { useReservationStore } from "@/stores/reservationsStore";

export default {
  setup() {
    const reservationStore = useReservationStore();
    return {
      exitIcon,
      failedScan,
      reservationStore,
    };
  },
  methods: {
    closeModal() {
      this.reservationStore.isFailModal = false;
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.8s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

/*
           * The following styles are auto-applied to elements with
           * transition="modal" when their visibility is toggled
           * by Vue.js.
           *
           * You can easily play with the modal transition by editing
           * these styles.
           */

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
