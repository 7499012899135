<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
  >
    <AddEventModal v-if="showEventModal" @close="showEventModal = false" />
    <div class="rounded-t mb-0 px-4 py-3 border-0 mt-4">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="text-lg text-primary font-medium">Liste des événements</h3>
        </div>
        <div>
          <button
            @click="showEventModal = true"
            class="flex bg-secondary px-6 py-2 rounded-md text-white my-4 shadow-secondary shadow-sm"
          >
            <img :src="eventIcon" class="mr-2" alt="addUser" />
            Ajouter événement
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- events table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead class="table-header">
          <tr class="border-b">
            <th
              class="table-th px-6 flex items-center align-middle border border-solid py-3 uppercase border-l-0 border-b-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              ID Événement
            </th>
            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              titre
            </th>
            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Date de l'événement
            </th>

            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Date d’ajout
            </th>

            <th
              class="table-th px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-center"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(event, index) in eventsStore.paginatedList"
            :key="index"
            class="border-b"
          >
            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              #{{ event.id }}
            </td>

            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ event.title }}
            </td>

            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ event.date.substring(0, 10) }}
            </td>

            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ event.createdAt.substring(0, 10) }}
            </td>
            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              <EventsActionsDropdown :event="event" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-around w-full py-6">
      <div>
        <button
          @click="eventsStore.previousPage"
          :disabled="eventsStore.currentPage === 1"
          class="border text-darkBlue px-3 py-2 rounded-md"
        >
          Précédente
        </button>
      </div>
      <div class="text-darkBlue flex justify-center items-center">
        Page {{ eventsStore.currentPage }} sur
        {{ Math.ceil(eventsStore.totalPages) }}
      </div>
      <div>
        <button
          @click="eventsStore.nextPage"
          :disabled="eventsStore.currentPage === eventsStore.totalPages"
          class="border text-darkBlue px-3 py-2 rounded-md"
        >
          Prochaine
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { useEventsStore } from "@/stores/eventsStore.js";
import EventsActionsDropdown from "../dropdowns/EventsActionsDropdown.vue";
import eventIcon from "@/assets/icons/event.svg";
import AddEventModal from "../modals/AddEventModal.vue";
export default {
  data() {
    return { showEventModal: false };
  },
  async mounted() {
    await this.eventsStore.getEvents();
  },
  setup() {
    const eventsStore = useEventsStore();
    return { eventIcon, eventsStore };
  },
  components: { EventsActionsDropdown, AddEventModal },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
<style scoped>
.table-header {
  background: #f9fafb;
}
.table-th {
  color: #8a92a6;
}
</style>
