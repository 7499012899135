<template>
  <div
    class="rounded-xl text-center px-2 py-[2px] font-medium text-[#37B735] border-[#37B735] border"
  >
    Présent
  </div>
</template>
<script>
export default {};
</script>
<style></style>
